<template>
  <DocumentCategory :id="90000" />
</template>

<script>
import DocumentCategory from '@/components/DocumentCategory.vue';
export default {
  components: { DocumentCategory },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
};
</script>
